@font-face {
font-family: 'OpenSansLight';
src: local('OpenSansLight'), url(./components/assets/fonts/OpenSans-Light.ttf) format('truetype');
}

.active {
  color: #0d93d2 !important
}

body {
  font-family: 'OpenSansLight' !important;
  color: #333 !important;
}

.sma{
  font-size: 17px !important;
}

.rwTextblack {
  color: #000 !important;
}

.rwbold {
  color: #0d93d2 !important;
}

.gifs {
  width: 130%;
  height: 65%;
  margin: 20px;
}