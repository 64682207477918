.col-md-1{
    width: 8,3333%;
    padding-left: 1px  !important;
    padding-right: 1px !important;
  }
  .rwclass{
  background-image: linear-gradient(to top, #ccc 0%,  #fff 50% , #ccc 100%);
  margin-top: -150px;

  }
  .rwclass2{
    margin-top: -5%;

  }


  .rwclass3{
    margin-bottom: 4%;

  }

  img {
    vertical-align: middle !important;
    border: 0 !important;
  }


  .st100{fill: #F7F7F7;}
  .st102{font-size: 70px;}
  .st100m{fill: #F7F7F7;}
  .st102m{font-size: 150px;}
  



  a[title]:hover:after {
    content: attr(title);
    position: absolute;
    background: black;
    color: white;
  }

  .box{
      border-radius: 10px;
      background: white;
      margin-bottom:  20px;
      padding-top: 30px;
      padding-bottom: 20px;
  }


   .butter {
    background-color: #0D93D2;
    border-radius: 5px !important; 
    color: white;
    font-size: 20px !important;
  }

.viz{
position: absolute;
top: 0;
left: 0;
}
.wrap{
position: relative;

}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
.ie{padding-bottom: 20%;

}
}




  .butter:hover {
    background-color: white;
    border-color: #0D93D2;

    color: #0D93D2;

  }